/* globals Backbone UAParser servars */

(function () {
  'use strict'
  Backbone.Model.prototype.idAttribute = '_id'

  if (window.UAParser) {
    window.ua = UAParser($.ua.get())
  }

  window.zc = {
    models: {},
    views: {},
    collections: {},
    routers: {},
    mixins: {}
  }

  var latestBrowserVersion = {
    firefox: 70,
    chrome: 88,
    chromium: 88
  }

  var minBrowserVersion = {
    firefox: latestBrowserVersion.firefox - 2,
    chrome: latestBrowserVersion.chrome - 2,
    chromium: latestBrowserVersion.chromium - 2
  }

  window.zc.conf = {
    dropbox: servars.dropbox,

    // settings for the audio pipeline profilers
    // true == enabled, false == disabled
    pipelineProfilers: {
      track: false,
      resampler: false
    },

    google: servars.google,

    minBrowserVersion: minBrowserVersion,

    latestBrowserVersion: latestBrowserVersion
  }

  if (!window.indexedDB) {
    var vendorIdb = window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB
    if (vendorIdb) window.indexedDB = vendorIdb
  }

  if (!window.AudioContext) {
    var vendorAudioContext = window.webkitAudioContext || window.mozAudioContext
    if (vendorAudioContext) window.AudioContext = vendorAudioContext
  }

  if (!navigator.cancelAnimationFrame) {
    var vendorCancelAnimationFrame = navigator.webkitCancelAnimationFrame || navigator.mozCancelAnimationFrame
    if (vendorCancelAnimationFrame) navigator.cancelAnimationFrame = vendorCancelAnimationFrame
  }
  if (!navigator.requestAnimationFrame) {
    var vendorRequestAnimationFrame = navigator.webkitRequestAnimationFrame || navigator.mozRequestAnimationFrame
    if (vendorRequestAnimationFrame) navigator.requestAnimationFrame = vendorRequestAnimationFrame
  }
})()
